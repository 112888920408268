import { Badge } from "./Badge";

export interface MethodologyBadgeProperties {
  methodology?: string;
}

export const MethodologyBadge = (
  properties: MethodologyBadgeProperties,
): JSX.Element => {
  const { methodology } = properties;

  let methodologyBadgeText = "Inquiry Based Learning";

  switch (methodology) {
    case "project": {
      methodologyBadgeText = "Project Based Learning";
      break;
    }
    case "direct": {
      methodologyBadgeText = "Direct Instruction";
      break;
    }
    default: {
      methodologyBadgeText = "Inquiry Based Learning";
      break;
    }
  }

  return (
    <Badge
      text={methodologyBadgeText}
      className="mr-2 mb-1"
      backgroundColour="bg-toolkitBlack"
      foregroundColour="text-white"
    ></Badge>
  );
};
