import clsx from "clsx";
import { Link } from "react-router-dom";
import { Loading, LoaderTypes } from "../Loading";
import ReactGA from "react-ga4";
import { useMemo, useCallback, CSSProperties, forwardRef } from "react";

interface ButtonProperties {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  title?: string;
  to?: string;
  variant: ButtonVariants;
  isLoading?: boolean;
  loadingColour?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  className?: string;
  icon?: React.ReactNode;
  ariaExpanded?: boolean;
  styles?: CSSProperties;
}

type ButtonVariants =
  | "toolkitTurquoise"
  | "toolkitTurquoiseLarge"
  | "toolkitBlue"
  | "toolkitBlueLarge"
  | "coachPink"
  | "greyOutline"
  | "greyOutlineLarge"
  | "round"
  | "link"
  | "none"
  | "greenRound";

const baseButtonStyles =
  "inline-flex justify-center border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2";

const buttonVariants: Record<ButtonVariants, string> = {
  toolkitTurquoise: `${baseButtonStyles} px-4 py-2 text-sm font-medium text-white bg-toolkitTurquoise hover:bg-opacity-80 focus:ring-toolkitTurquoise`,
  toolkitTurquoiseLarge: `${baseButtonStyles} w-full px-8 py-3 text-base font-medium text-white bg-toolkitTurquoise hover:bg-opacity-80 md:py-4 md:text-lg md:px-8 focus:ring-toolkitTurquoise`,
  toolkitBlue: `${baseButtonStyles} px-4 py-2 text-sm font-medium text-white bg-toolkitBlue hover:bg-opacity-80 focus:ring-toolkitBlue`,
  toolkitBlueLarge: `${baseButtonStyles} w-full px-8 py-3 text-base font-medium text-white bg-toolkitBlue hover:bg-opacity-80 md:py-4 md:text-lg md:px-8 focus:ring-toolkitBlue`,
  coachPink: `${baseButtonStyles} px-4 py-2 text-sm font-medium text-white bg-coachPink hover:bg-opacity-80 focus:ring-coachPink`,
  greyOutline: `${baseButtonStyles} px-4 py-2 border-gray-300 text-sm font-medium bg-white hover:bg-gray-50`,
  greyOutlineLarge: `${baseButtonStyles} w-full px-8 py-3 border-gray-300 text-base font-medium bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-8 focus:ring-toolkitGrey`,
  round:
    "p-2 border border-gray-300 bg-white shadow-sm hover:bg-gray-50 rounded-full",
  link: "inline-flex py-2 hover:text-toolkitTurquoise underline",
  none: "inline-flex py-2",
  greenRound:
    "p-2 border border-gray-300 bg-green-500 text-white shadow-sm hover:bg-green-400 rounded-full",
};

const trackEvent = (
  eventCategory?: string,
  eventAction?: string,
  eventLabel?: string,
) => {
  if (eventCategory && eventAction) {
    ReactGA.event({
      category: eventCategory.toLowerCase(),
      action: `${eventAction.toLowerCase()}_click`,
      label: eventLabel,
    });
  }
};

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProperties
>(
  (
    {
      children,
      onClick,
      disabled = false,
      type = "button",
      title = "",
      to,
      variant,
      isLoading = false,
      loadingColour,
      eventAction,
      eventCategory,
      eventLabel,
      className: customClassName,
      icon,
      ariaExpanded,
      styles,
    }: ButtonProperties,
    reference,
  ): JSX.Element => {
    const disabledClassnames = disabled ? "opacity-50 cursor-not-allowed" : "";
    const className = useMemo(
      () => clsx(buttonVariants[variant], customClassName, disabledClassnames),
      [variant, customClassName, disabledClassnames],
    );

    const handleOnClick = useCallback(() => {
      if (disabled || isLoading) return;

      onClick?.();

      trackEvent(eventCategory, eventAction, eventLabel);
    }, [
      onClick,
      disabled,
      isLoading,
      eventCategory,
      eventAction,
      eventLabel,
      variant,
    ]);

    const buttonContent = (
      <>
        {isLoading && (
          <Loading loaderType={LoaderTypes.Spinner} colour={loadingColour} />
        )}
        {icon && <span className="mr-2">{icon}</span>}
        {children}
      </>
    );

    return to ? (
      <Link
        to={disabled ? "" : to}
        className={className}
        onClick={handleOnClick}
        style={styles}
        aria-label={title}
        aria-busy={isLoading}
        aria-expanded={ariaExpanded}
        ref={reference as React.ForwardedRef<HTMLAnchorElement>}
      >
        {buttonContent}
      </Link>
    ) : (
      <button
        ref={reference as React.ForwardedRef<HTMLButtonElement>}
        type={type}
        title={title}
        disabled={disabled || isLoading}
        onClick={handleOnClick}
        className={className}
        style={styles}
        aria-label={title}
        aria-busy={isLoading}
        aria-expanded={ariaExpanded}
      >
        {buttonContent}
      </button>
    );
  },
);
