import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { NavLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Profile } from "../../types/Profile";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Gravatar from "../Gravatar";

interface ProfileMenuProperties {
  profile: Profile;
  // renders the profile menu as a dropdown menu, if false builds a vertical list instead
  isDropDown: boolean;
}

export function ProfileMenu(properties: ProfileMenuProperties): JSX.Element {
  const { profile, isDropDown } = properties;

  const navigate = useNavigate();

  const navigationItems = [
    /*
    { path: "/dashboard", label: "Dashboard" },
    { path: "/studio", label: "The Studio" },*/
    { path: "/outcomes", label: "Outcomes" },
    { path: "/uploads", label: "Uploads" },
    { path: "/sharing", label: "Sharing" },
    { path: "/profile", label: "Profile" },
  ];

  const mobileLinkStyle =
    "bg-opacity-0 text-md text-toolkitBlack block font-medium rounded-md bg-white px-3 py-2 hover:bg-opacity-10";

  if (isDropDown) {
    return (
      <div className="hidden md:block z-40">
        <div className="flex items-center">
          {/* Profile dropdown */}
          <Menu as="div" className="ml-4 relative">
            {({ open }) => (
              <>
                <Menu.Button className="flex items-center">
                  <div className="bg-white text-sm rounded-full ring-toolkitGrey ring-2 ring-offset-1 hover:ring-toolkitTurquoise focus:ring-toolkitTurquoise">
                    <span className="sr-only">Open profile menu</span>
                    <Gravatar
                      className="h-10 w-10 rounded-full"
                      hash={profile.id}
                      title={profile?.name || profile?.email || profile?.id}
                    />
                  </div>
                  <ChevronDownIcon
                    className="hidden flex-shrink-0 ml-2 h-5 w-5 text-toolkitBlack md:block"
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-60 py-4 px-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    {navigationItems.map(item => (
                      <Menu.Item key={item.path}>
                        {({ active }) => (
                          <Disclosure.Button
                            as={NavLink}
                            key={item.path}
                            to={item.path || ""}
                            className={clsx(
                              active ? "bg-toolkitGrey" : "",
                              "block px-2 py-2 rounded-md hover:bg-toolkitGrey transition ease-in-out duration-150",
                            )}
                          >
                            <p className="text-md font-medium">{item.label}</p>
                          </Disclosure.Button>
                        )}
                      </Menu.Item>
                    ))}
                    <Menu.Item key="logout">
                      <div>
                        <hr className="my-2" />

                        <button
                          onClick={() => {
                            navigate("/logout");
                          }}
                          className="block w-full text-left px-2 py-2 rounded-md hover:bg-toolkitGrey transition ease-in-out duration-150"
                        >
                          <p className="text-md font-medium">Logout</p>
                        </button>
                      </div>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-4 pb-3 border-t border-white">
      <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          <Gravatar
            className="h-10 w-10 rounded-full"
            hash={profile.id}
            title={profile?.name || profile?.email || profile?.id}
          />
        </div>
        <div className="ml-3">
          <div className="text-base font-medium text-toolkitBlack">
            {profile.name}
          </div>
          <div className="text-sm font-medium text-toolkitBlack">
            {profile.email}
          </div>
        </div>
      </div>
      <div className="mt-3 px-2 space-y-1">
        {navigationItems.map(item => (
          <NavLink
            key={item.path}
            to={item.path || ""}
            className={({ isActive }) => {
              return clsx(mobileLinkStyle, isActive ? "underline" : "");
            }}
          >
            {item.label}
          </NavLink>
        ))}
        <button
          key={"logout"}
          onClick={function () {
            navigate("/logout");
          }}
          className={mobileLinkStyle}
        >
          Logout
        </button>
      </div>
    </div>
  );
}
