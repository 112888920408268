import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
//import imgLessonPlans from "../../images/undraw_adventure_map_hnin.svg";
//import imgAssessments from "../../images/undraw_learning_re_32qv.svg";
//import imgComments from "../../images/undraw_personal_opinions_re_qw29.svg";

import Onboarder from "../onboarding/Onboarder";
import { NavBar } from "../navigation/NavBar";
import { NavItem } from "../../types/NavItem";
import { UpSellContainer } from "../UpSellContainer";

export const AppLayout = (): JSX.Element => {
  const navItems: NavItem[] = [
    { path: "/lessons", label: "Plan Lessons" },
    { path: "/assessments", label: "Build Assessments" },
    { path: "/units", label: "Design Units" },
    { path: "/comments", label: "Give Feedback" },
    { path: "/studio", label: "The Studio" },
    /*{
      flyout: {
        id: "tools",
        footerText: "Request a Tool or Feature",
        footerPath: "/roadmap",
        navItems: [
          {
            path: "/lessons",
            imageSrc: imgLessonPlans,
            label: "Plan Lessons",
            description:
              "Plan and manage your Lessons, Outcomes, Quiz Questions and Pedagogy",
          },
          {
            path: "/assessments",
            imageSrc: imgAssessments,
            label: "Build Assessments",
            description:
              "Construct rich assessments with robust rubric driven marking",
          },
          {
            path: "/comments",
            imageSrc: imgComments,
            label: "Give Feedback",
            description:
              "Give personalised Feedback with comments aligned to your outcomes and expectations",
          },
        ],
      },
      label: "My Tools",
    },
    { path: "/studio", label: "Studio" },
    */
  ];

  return (
    <>
      <Onboarder />
      <NavBar navItems={navItems} />
      <UpSellContainer />
      <Outlet />
      <Footer />
    </>
  );
};
